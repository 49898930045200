import React from "react";
import { Link } from "react-router-dom";
import pmp from "../assets/pmp.jpg";
import chrombit from "../assets/chrombit.jpg"

const ProjectPage = () => {
    return (
    <section id="projects" className="min-h-screen bg-white py-24">
      <div className="max-w-7xl mx-auto px-8 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-center mb-8">Projects</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6">
          <Link to="pmp" className="hover:scale-105 transition ease-in-out cursor-pointer">
              <img src={pmp} alt="pmp" className="w-full mb-4 rounded-md"/>
              <h2 className="text-2xl font-semibold mb-2">PMP Music Studio</h2>
              <p className="text-lg text-gray-600">Rent App</p>
          </Link>
          <Link to="chrombit" className="hover:scale-105 transition ease-in-out cursor-pointer">
              <img src={chrombit} alt="chrombit" className="w-full mb-4 rounded-md"/>
              <h2 className="text-2xl font-semibold mb-2">Chrombit</h2>
              <p className="text-lg text-gray-600">Website Company</p>
          </Link>
        </div>
        
      </div>
    </section>
    );
};

export default ProjectPage;