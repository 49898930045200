import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import ProjectPage from './pages/ProjectPage';
import AboutPage from './pages/AboutPage';
import ProjectPMP from './pages/PmpPage';
import ProjectChrombit from './pages/ChrombitPage';


function App() {
  return (
    <Router>
      <Navbar />
      <div className="pt-16">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/pmp" element={<ProjectPMP />} />
          <Route path="/chrombit" element={<ProjectChrombit />} />
        </Routes>
      </div>
    </Router>
  );
};

function MainPage() {
  const location = useLocation();

  useEffect(() => {
    // Mengganti title berdasarkan path
    switch (location.pathname) {
      case '/pmp':
        document.title = 'Project PMP - My Portfolio';
        break;
      case '/chrombit':
        document.title = 'Chrombit - My Portfolio';
        break;
      default:
        document.title = 'Home - My Portfolio';
    }
  }, [location]);

  return (
    <div>
      <HomePage />
      <ProjectPage />
      <AboutPage />
    </div>
  );
}

export default App;
