import React, { useEffect } from "react";
import chrombitdesk from "../assets/chrombitdesk.png";
import laptopmain from "../assets/laptopchrom.png";
import sub1 from "../assets/sub1.png";
import sub2 from "../assets/sub2.png";
import sub3 from "../assets/sub3.png";
import sub4 from "../assets/sub4.png";
import oldversion from "../assets/oldversion.jpg";
import newone1 from "../assets/newone1.jpg";
import newone2 from "../assets/newone2.jpg";
import newone3 from "../assets/newone3.png";
import figma from "../assets/figma.png";

const ProjectChrombit = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Project Chrombit - My Porfolio';
    }, []);

    const colors = [
        { hex:'#77ACF1', name: 'Blue' },
        { hex:'#212529', name: 'Black' },
        { hex:'#979797', name: 'Gray' },
        { hex:'#FFFFFF', name: 'White' },
    ];
    
    return (
        <section className="min-h-screen" id="chrombit">
        <div className="max-w-4xl mx-auto px-4 py-32 text-center">
            <h1 className="text-6xl font-bold mb-4">Chrombit</h1>
            <p className="text-xl text-gray-700">Website Company</p>
            <img src={chrombitdesk} alt="pmphp" className="w-full md:w-4/5 mb-6 object-center mx-auto "/>
        </div>

        {/* Project Overview */}
        <div className="max-w-4xl mx-auto px-4">
            <div className="grid grid-cols-2">
                <h2 className="text-3xl font-bold mb-6">Project Overview</h2>
                <p className="text-md md:text-lg text-gray-600">This project focused on redesigning <span className="font-bold text-black">Chrombit's</span> corporate website, with the aim of improving the visual appearance, user experience, and functionality of the site to support business strategy and customer needs.</p>
            </div>
        </div>
        <div className="flex mb-52">
                <div className="mt-4 md:mt-24 mx-auto">
                    <img src={laptopmain} alt="laptopmain" className="w-1/2 object-center mx-auto"/>
                </div>
                <div className="absolute grid grid-cols-2">
                    <div className="relative">
                        <img src={sub1} alt="sub1" className="absolute w-full scale-[65%] xl:scale-[60%] xl:ml-12 z-10 hover:md:scale-[70%] hover:xl:scale-[65%] duration-500 ease-in-out"/>
                        <img src={sub4} alt="sub4" className="absolute w-1/2 scale-120 z-0 mt-20 xl:scale-110 md:mt-40 lg:mt-60 xl:ml-20 hover:md:scale-125 hover:xl:scale-115 duration-500 ease-in-out"/>
                    </div>
                    <div className="relative">
                        <img src={sub3} alt="sub3" className="absolute w-full scale-[70%] md:scale-[85%] xl:scale-[70%] z-0 mt-24 md:mt-48 md:ml-12 lg:mt-64 xl:mt-60 xl:ml-24 hover:md:scale-[90%] hover:xl:scale-[75%] duration-500 ease-in-out"/>
                        <img src={sub2} alt="sub2" className="w-1/2 md:w-3/5 z-10 ml-8 xl:ml-32 xl:w-1/2 hover:md:scale-105 duration-500 ease-in-out"/>
                    </div>
                </div>
            </div>
            
            {/* Old Version */}
            <div className="max-w-4xl mx-auto px-4 py-24">
                <h2 className="text-3xl font-bold mb-6">Old Version</h2>
                <img src={oldversion} alt="oldversion" className="w-full mb-6 object-center mx-auto"/>
            </div>

            {/* New One */}
            <div className="max-w-4xl mx-auto px-4 mt-24">
                <h2 className="text-3xl font-bold mb-6">New One</h2>
                <div className="grid grid-cols-3 gap-6 rounded-xl">
                    <div className="col-span-2">
                        <img src={newone1} alt="new-1" className="w-full mb-6 rounded-xl shadow-xl"/>
                    </div>
                    <div className="col-span-1">
                        <img src={newone2} alt="new-2" className="w-full mb-6 mx-auto rounded-xl shadow-xl"/>
                    </div>
                </div>
            </div>
            <div className="max-w-4xl mx-auto px-4 mt-24">
                <h2 className="text-3xl font-bold mb-6">New One</h2>
                <img src={newone3} alt="oldversion" className="w-full mx-auto"/>
            </div>

            {/* Design System */}
            <div className="max-w-4xl mx-auto px-4 py-24">
                <h2 className="text-3xl font-bold mb-6">Design System</h2>
                <div className="max-w-4xl bg-gray-200 rounded-3xl">
                    <h3 className="text-3xl font-semibold mx-12 py-12">Colors</h3>
                    <div className="grid grid-cols-2 md:grid-cols-5 mx-16 md:ml-28 lg:mx-12 gap-20 md:gap-36 lg:gap-8">
                        {colors.map((color, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <div
                                className="w-32 h-32 rounded-xl"
                                style={{ backgroundColor: color.hex }}
                            />
                            <p className="mt-4 text-lg font-bold">{color.hex}</p>
                        </div>
                        ))}
                    </div>
                    <div className="mt-12">
                        <div className="grid grid-cols-1 md:grid-cols-2">
                            <div className="">
                                <h3 className="text-3xl font-semibold mx-12 py-12">Font</h3>
                                <h2 className="text-6xl md:text-8xl font-black mx-12 font-poppins mb-4">POPPINS</h2>
                                <p className="text-md md:text-lg mx-12 font-poppins tracking-widest">
                                    Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz
                                </p>
                                <p className="text-md md:text-lg mx-12 py-6 font-poppins tracking-widest">
                                    0123456789
                                </p>
                            </div>
                            <div className="">
                                <h3 className="text-3xl font-semibold ml-12 md:ml-36 lg:ml-24 py-12">Tools</h3>
                                <img src={figma} alt="figma" className="ml-12 mb-12 md:ml-36 lg:ml-24 w-[30%]"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default ProjectChrombit;