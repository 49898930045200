import React from 'react';
import mypicture from '../assets/fotohafiz.jpg';
import cv from '../assets/CV_Hafiz.pdf';

const skillData= [
        {
            skill1: 'User Interface',
            skill2: 'User Experience',
            skill3: 'Design System',
            skill4: 'User Flows',
            skill5: 'Mockup',
            skill6: 'Wireframe',
            skill7: 'Prototype',
            skill8: 'HTML,CSS, JS',
        },
    ];

const AboutPage = () => {

  return (
    <section id="about" className="py-24">
        <div className='flex items-center justify-center'>
            <div className="container grid grid-cols-1 md:grid-cols-3 px-8">
                <div className="col-span-2 self-center">
                    <h2 className="text-sm text-gray-700 lg:text-lg">Hello There!</h2>
                    <h1 className="text-2xl font-bold mt-2 lg:mt-6 lg:text-4xl">I'm Hafizan Fadli Sulharis</h1>
                    <p className="text-md mt-2 mb-6 pr-0 md:pr-8 lg:mt-6 lg:text-lg">I'm a UI/UX designer based in Jakarta, Indonesia. a recent graduate of the Informatics Engineering program at Paramadina University. I am committed to continuous learning and making meaningful contributions in every given opportunity I am eager to apply my skills and creativity to contribute to business growth.</p>
                    <a
                    href={cv}
                    download="Resume_HafizanFadliSulharis"
                    className="rounded-md bg-white border-2 border-black px-6 py-3.5 text-sm font-semibold text-black hover:bg-black hover:text-white hover:transition duration-300 ease-in-out"
                >Resume CV</a>
                </div>
                <div className="w-full rounded-lg bg-white mt-8">
                    <img src={mypicture} className='w-full rounded-lg object-cover object-center' alt='mypic'></img>
                </div>
            </div>
        </div>
        <div className="flex items-center justify-center py-20">
            <div className='container px-8'>
                <h1 className="text-4xl font-bold mb-8">Skill</h1>
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                    {skillData.map((skill, index) => (
                        Object.values(skill).map((value, subIndex) => (
                            <p key={`${index}-${subIndex}`} className='text-lg text-black font-medium border-2 border-black rounded-md py-2 px-4 text-center'>
                                {value}
                            </p>
                        ))
                    ))}
                </div>
                <div className='grid grid-cols-1 mt-4'>
                    <p className='text-lg text-black font-medium border-2 border-black rounded-md py-2 px-4 text-center'>Software Editor : Figma, Adobe Photoshop, Adobe Illustrator, Whimsical</p>
                </div>
            </div>
        </div>
    </section>
  );
};

export default AboutPage;