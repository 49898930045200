import React from "react";

const HomePage = () => {
    return (
        <section id="home" className="min-h-screen flex items-center">
            <div className="container mx-auto px-8">
                <h1 className="text-5xl font-bold lg:text-7xl">Hi, There!</h1>
                <p className="text-md mt-6 md:text-lg lg:text-2xl">I'm Hafizan Fadli, a UI/UX designer dedicated to crafting engaging and intuitive digital experiences. Let's collaborate to bring your vision to life and create unforgettable digital journeys.</p>
            </div>
        </section>
    );
};

export default HomePage;