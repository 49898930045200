import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/react'


const Navbar = () => {
    const location = useLocation();

    return (
        <Disclosure as="nav" className="bg-white shadow-md fixed w-full z-10">
            {({ open, close }) => (
                <>
                <div className="max-w-7x1 mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <a href="/" className="text-2xl font-bold text-gray-800">HF</a>
                    </div>
                    <div className="hidden md:flex space-x-20 text-lg">
                        <Link
                        to="home"
                        smooth={true}
                        duration={500}
                        className={`text-gray-800 hover:text-gray-600 cursor-pointer ${location.pathname === '/' ? '' : 'hidden'}`}
                        >
                        Home
                        </Link>
                        <Link
                        to="projects"
                        smooth={true}
                        duration={500}
                        className={`text-gray-800 hover:text-gray-600 cursor-pointer ${location.pathname === '/' ? '' : 'hidden'}`}
                        >
                        Projects
                        </Link>
                        <Link
                        to="about"
                        smooth={true}
                        duration={500}
                        className={`text-gray-800 hover:text-gray-600 cursor-pointer ${location.pathname === '/' ? '' : 'hidden'}`}
                        >
                        About
                        </Link>
                        {/* Link ke halaman detail */}
                        <a
                        href="/"
                        className={`text-gray-800 hover:text-gray-600 cursor-pointer ${location.pathname !== '/' ? '' : 'hidden'}`}
                        >
                        Back to Home
                        </a>
                    </div>
                    <div className="md:hidden">
                                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-600 hover:bg-gray-300 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon aria-hidden="true" className="block h-6 w-6" />
                                    ) : (
                                        <Bars3Icon aria-hidden="true" className="block h-6 w-6" />
                                    )}
                                </DisclosureButton>
                    </div>
                </div>
            </div>
                <DisclosurePanel className="md:hidden">
                    <div className="px-2 pt-2 pb-3 space-x-1 sm:px-3">
                        <Link
                            to="home"
                            smooth={true}
                            duration={500}
                            onClick={() => close()}
                            className={`block px-3 py-2 text-gray-800 hover:bg-gray-200 hover:text-gray-600 rounded-md cursor-pointer ${location.pathname === '/' ? '' : 'hidden'}`}
                        >
                        Home
                        </Link>
                        <Link
                            to="projects"
                            smooth={true}
                            duration={500}
                            onClick={() => close()}
                            className={`block px-2 py-2 text-gray-800 hover:bg-gray-200 hover:text-gray-600 rounded-md cursor-pointer ${location.pathname === '/' ? '' : 'hidden'}`}
                        >
                        Projects
                        </Link>
                        <Link
                            to="about"
                            smooth={true}
                            duration={500}
                            onClick={() => close()}
                            className={`block px-2 py-2 text-gray-800 hover:bg-gray-200 hover:text-gray-600 rounded-md cursor-pointer ${location.pathname === '/' ? '' : 'hidden'}`}
                        >
                            About
                        </Link>
                        <a
                            href="/"
                            onClick={() => close()}
                            className={`block px-2 py-2 text-gray-800 hover:bg-gray-200 hover:text-gray-600 rounded-md cursor-pointer ${location.pathname !== '/' ? '' : 'hidden'}`}
                        >
                            Back to Home
                        </a>
                    </div>
                </DisclosurePanel>
                </>
            )}
        </Disclosure>
    );
};

export default Navbar;