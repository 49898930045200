import React, { useEffect } from "react";
import pmphp from "../assets/phonepmp.png";
import hpproject from "../assets/hpproject.png";
import hpproject2 from "../assets/hpproject2.png";
import sitemap from "../assets/sitemap.png";
import wireframe from "../assets/wireframe.png";
import highfidelity from "../assets/highfidelity.png";
import booking from "../assets/booking.png";
import booking2 from "../assets/booking2.png";
import booking3 from "../assets/booking3.png";
import { ExclamationCircleIcon, LightBulbIcon } from "@heroicons/react/24/outline";

const ProjectPMP = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Project PMP - My Porfolio';
    }, []);


    
    return (
        <section className="min-h-screen" id="pmp">
        <div className="max-w-4xl mx-auto px-4 py-32 text-center">
            <h1 className="text-6xl font-bold mb-4">PMP Music Studio</h1>
            <p className="text-xl text-gray-700">Rent App</p>
            <img src={pmphp} alt="pmphp" className="w-full md:w-4/5 mb-6 object-center mx-auto"/>
        </div>

        {/* Project Overview */}
        <div className="max-w-4xl min-h-screen grid grid-cols-1 md:grid-cols-4 gap-6 mx-auto px-4">
            <div className="md:relative row-start-1 col-span-2">
                <h2 className="text-3xl font-bold mb-6">Project Overview</h2>
                <div className="relative md:absolute bg-blue-400 rounded-3xl h-[400px] md:h-[80%] inset-x-0 md:bottom-0">
                    <img src={hpproject} alt="hpproject" className="absolute w-3/5 md:w-3/4 object-center mx-auto inset-x-0 bottom-0 hover:md:w-4/5"/>
                </div>
            </div>
            <div className="md:relative col-span-2 md:row-start-1 md:col-span-2">
                <p className="text-md md:text-lg text-gray-600"><span className="font-bold text-black">PMP Music Studio App</span> is an application platform that aims to provide convenience in renting music studios for musicians and producers.<br/>
                <br/>Dengan aplikasi ini, konsumen dapat memesan dan menggunakan studio musik dengan cepat dan efisien.</p>
                <div className="md:absolute rounded-3xl bg-gray-200 h-[260px] md:h-3/5 inset-x-0 md:bottom-0 mt-6">
                    <img src={hpproject2} alt="hpproject2" className="w-3/5 md:w-3/4 object-center mx-auto hover:md:w-4/5"/>
                </div>
            </div>
        </div>

        {/* Problem and Solution */}
        <div className="max-w-4xl grid grid-cols-1 md:grid-cols-2 gap-6 mx-auto px-4 py-32">
            <div className="border-2 border-black rounded-3xl py-6 mb-6 md:mb-24">
                <div className="flex justify-between items-center px-8 py-4">
                    <h2 className="text-3xl font-bold">Problem</h2>
                    <div className="flex h-14 w-14 items-center justify-center rounded-xl bg-black text-white">
                        <ExclamationCircleIcon aria-hidden="true" className="h-8 w-8"/>
                    </div>
                </div>
                <p className="text-md md:text-lg text-gray-600 mb-6 px-8 py-8">The <span className="font-bold text-black">scheduling</span> and <span className="font-bold text-black">booking</span> system at PMP Music Studio is currently done manually and via the WhatsApp application. This process is less efficient because it requires consumers to contact the studio to check the availability of the place.</p>
            </div>
            <div className="border-2 border-black rounded-3xl py-6 md:mt-24">
                <div className="flex justify-between items-center px-8 py-4">
                    <h2 className="text-3xl font-bold">Solution</h2>
                    <div className="flex h-14 w-14 items-center justify-center rounded-xl bg-black text-white">
                        <LightBulbIcon aria-hidden="true" className="h-7 w-7"/>
                    </div>
                </div>
                <p className="text-md md:text-lg text-gray-600 mb-6 px-8 py-8">Develop a mobile application for PMP Music Studio that allows consumers to view studio availability in real-time, make direct reservations, manage bookings with an interactive calendar, to improve operational efficiency and provide a better experience for consumers.</p>
            </div>
        </div>

        {/* Sitemap */}
        <div className="max-w-4xl mx-auto px-4 md:py-24">
            <h2 className="text-3xl font-bold mb-6">Sitemap</h2>
            <p className="text-md md:text-lg text-gray-600 md:w-3/4 mb-16">Sitemap helps in planning the overall application structure, ensuring all pages and features are organized logically.</p>
            <img src={sitemap} alt="sitemap" className="w-full mb-6 object-center mx-auto"/>
        </div>

        {/* Wireframe */}
        <div className="max-w-4xl mx-auto px-4 py-24">
            <h2 className="text-3xl font-bold mb-6">Wireframe</h2>
            <p className="text-md md:text-lg text-gray-600 md:w-5/6 mb-16">Arrange wireframe with low-fidelity using the whimsical application. Assist in designing the visual layout of the application and ensuring the structure and navigation that is intuitive before the next stage.</p>
            <img src={wireframe} alt="sitemap" className="w-full mb-6 object-center mx-auto"/>
        </div>

        {/* High fidelity */}
        <div className="max-w-4xl mx-auto px-4 md:py-32">
            <div className="flex bg-blue-400 rounded-3xl h-64">
                <div className="mx-auto px-4 md:px-8 py-10">
                    <h2 className="text-3xl text-white font-bold mb-4 md:mb-8">High Fidelity</h2>
                    <p className="text-sm md:text-lg text-white">After creating a Wireframe with Low-Fidelity, I continued by developing the design with High-Fidelity to add deeper visual details to each element of the application.</p>
                </div>
                <div className="md:mr-8 py-4 self-center">
                    <img src={highfidelity} alt="highfidelity" className="w-full object-center scale-110 mx-auto hover:scale-105"/>
                </div>
            </div>
        </div>

        {/* Booking */}
        <div className="max-w-4xl mx-auto px-4 py-24 md:py-32">
                <div className="py-4 self-center">
                    <img src={booking} alt="booking" className="w-full object-center mx-auto"/>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-24 md:py-0">
                    <div className="bg-blue-400 rounded-3xl h-100">
                        <img src={booking2} alt="booking2" className="w-40 md:w-2/5 py-6 object-center mx-auto hover:md:scale-105"/>
                    </div>
                    <div className="md:relative">
                        <div className="relative md:absolute bg-gray-200 rounded-3xl h-80 inset-x-0 bottom-0">
                            <img src={booking3} alt="booking2" className="absolute w-full hover:scale-105"/>
                        </div>
                    </div>
                </div>
        </div>
        
        </section>
    );
};

export default ProjectPMP;